import "../Styles/Hero.scss";
export default function Hero() {
    return (
        <>
            <h1>SATYA PATRA</h1>
            <h2>Software Engineer, Front end Developer</h2>
            <div id="scroll">
                <div id="circle"></div>
            </div>
        </>
    );
}
