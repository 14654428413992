import "../Styles/Navbar.scss";
import { IconContext } from "react-icons/lib";
import { VscChromeClose } from "react-icons/vsc";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { useState } from "react";
export default function NewNavbar() {
    const [visible, setVisible] = useState<boolean>(false);
    const [iconBackground, setIconBackground] = useState<string>("#fff");
    return (
        <>
            <IconContext.Provider
                value={{
                    size: "2em",
                    color: iconBackground,
                    style: { verticalAlign: "middle" },
                }}
            >
                <button
                    className="menu-button"
                    onClick={() => setVisible(!visible)}
                    onMouseOver={() => setIconBackground("#8ed1fc")}
                    onMouseLeave={() => setIconBackground("#fff")}
                >
                    {visible ? <VscChromeClose /> : <HiOutlineMenuAlt4 />}
                </button>
            </IconContext.Provider>
            <nav className="flex">
                <ul id="top-nav" className="new-navbar fade-down" data-visible={visible}>
                    <li className="nav-item">
                        <a href="/#">
                            <span aria-hidden="true">00</span>Home
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/#expertise">
                            <span aria-hidden="true">01</span>Expertise
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/#work">
                            <span aria-hidden="true">02</span>Work
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/#experience">
                            <span aria-hidden="true">03</span>Experience
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/#contact">
                            <span aria-hidden="true">04</span>Contact
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    );
}
