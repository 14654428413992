import { Message } from "./Contact";
import "../Styles/Contact.scss";
export default function Testimonial({
    name,
    src,
    content,
    designation,
    background,
    basis
}: Message) {
    return (
        <>
            <div className="testimonial" style={{ backgroundColor: background, flexBasis: basis }}>
                <div className="img">
                    {/* <img src={src} alt={name} className="profile" /> */}
                </div>
                <p className="content">{content}</p>
                <p className="name">{"- " + name}</p>
                <p className="designation">{designation}</p>
            </div>
        </>
    );
}
