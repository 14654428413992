import { useEffect } from "react";
import "./App.css";
import Contact from "./Components/Contact";
import Experience from "./Components/Experience";
import Expertise from "./Components/Expertise";
import Hero from "./Components/Hero";
import NewNavbar from "./Components/NewNavbar";
import Work from "./Components/Work";
import { useIntersectionObserver } from "./utils/useIntersectionObserver";

function App() {
    const { ref: workRef, visible: workVisible } = useIntersectionObserver({});
    const { ref: expertiseRef, visible: expertiseVisible } =
        useIntersectionObserver({});
    const { ref: heroRef, visible: heroVisible } = useIntersectionObserver({});
    const { ref: experienceRef, visible: experienceVisible } =
        useIntersectionObserver({});
    const { ref: contactRef, visible: contactVisible } =
        useIntersectionObserver({});
    useEffect(() => {
        if (expertiseRef.current && expertiseVisible) {
            document.querySelector("#navbar")?.classList.add("sticky");
            expertiseRef.current?.classList.add("fade-in");
        }
    }, [expertiseVisible, expertiseRef]);
    useEffect(() => {
        if (heroVisible)
            document.querySelector("#navbar")?.classList.remove("sticky");
        if (
            expertiseVisible ||
            workVisible ||
            experienceVisible ||
            contactVisible
        )
            document.querySelector("#navbar")?.classList.add("sticky");
    }, [
        heroVisible,
        expertiseVisible,
        workVisible,
        experienceVisible,
        contactVisible,
    ]);
    return (
        <>
            <section id="navbar">
                <NewNavbar />
            </section>
            <section id="hero" ref={heroRef}>
                <Hero />
            </section>
            <section id="expertise" ref={expertiseRef}>
                <Expertise visible={expertiseVisible} />
            </section>
            <section id="work" ref={workRef}>
                <Work visible={workVisible} />
            </section>
            <section id="experience" ref={experienceRef}>
                <Experience visible={experienceVisible} />
            </section>
            <section id="contact" ref={contactRef}>
                <Contact visible={contactVisible} />
            </section>
            <section id="footer"></section>
        </>
    );
}

export default App;
