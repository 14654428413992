import "../Styles/Contact.scss";
import { Visible } from "./Expertise";
import Testimonial from "./Testimonial";
export type Message = {
    name: string;
    src: string;
    content: string;
    designation: string;
    background: string;
    basis?: string;
};
export default function Contact({ visible }: Visible) {
    const testimonials = [
        {
            name: "lorem ipsum",
            src: "lorem",
            content:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quaerat voluptate iusto ratione numquam dignissimos possimus illo reprehenderit enim eligendi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, minus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, placeat. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint, velit?",
            designation: "lorem",
            background: "aqua",
        },
        {
            name: "lorem ipsum",
            src: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad illo ullam rerum debitis a quo nemo officia consequatur quae corrupti?",
            content: "lorem",
            designation: "lorem",
            background: "lightblue",
        },
        {
            name: "lorem ipsum",
            src: "lorem",
            content:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, autem! Totam, fuga! Consequuntur a, doloremque modi earum unde voluptates aliquam.",
            designation: "lorem",
            background: "skyblue",
        },
    ].map((msg: Message, i: number) => (
        <Testimonial
            name={msg.name}
            src={msg.src}
            content={msg.content}
            designation={msg.designation}
            key={i}
            background={msg.background}
        />
    ));
    return (
        <>
            <div className="contact-container">
                <div className="form">
                    <h1>Let's Work Together !</h1>
                    <span>
                        <a href="mailto:satyaarjun.patra@gmail.com">
                            satyaarjun.patra@gmail.com
                        </a>
                    </span>
                    <div className="row">
                        <a href="https://www.instagram.com/arjunn._">
                            Instagram
                        </a>
                        <a href="https://www.linkedin.com/in/satyaa21">
                            Linkedin
                        </a>
                        <a href="https://www.github.com/arjunn21">Github</a>
                        <a href="https://www.upwork.com/freelancers/~012ab0cfbeb3cd0d90">
                            Upwork
                        </a>
                    </div>
                </div>
                <div className="testimonials">
                    <Testimonial
                        name="lorem ipsum"
                        src="lorem"
                        content="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita sed illum repellat iste vitae voluptatibus magnam odit reiciendis iure repudiandae cupiditate nisi, libero debitis deleniti illo aliquid aliquam nihil omnis error vero, voluptas nobis reprehenderit sunt? Optio vitae corrupti est! Esse qui id vero, omnis provident praesentium impedit! Voluptate odit ipsa explicabo adipisci perspiciatis! Asperiores perferendis natus ratione aspernatur, neque error voluptatum assumenda quaerat unde molestias et exercitationem magni soluta veritatis dolorem officiis amet facere maxime itaque ad dolores aut architecto? Eaque, omnis ea! Natus aperiam quia quas. Saepe dolores sit commodi iure culpa distinctio unde repellat ab dolor quam."
                        designation="lorem"
                        background="aqua"
                    />

                    <div className="stack">
                        <Testimonial
                            name="lorem ipsum"
                            src="lorem"
                            content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quaerat voluptate iusto ratione numquam dignissimos possimus illo reprehenderit enim eligendi!"
                            designation="lorem"
                            background="aqua"
                        />
                        <Testimonial
                            name="lorem ipsum"
                            src="lorem"
                            content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quaerat voluptate iusto ratione numquam dignissimos possimus illo reprehenderit enim eligendi!"
                            designation="lorem"
                            background="aqua"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
