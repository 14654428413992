import { useRef, useState, useEffect } from "react";
type Props = {
    options?: {
        root: null;
        rootMargin: string;
        threshold: number;
    };
};
export function useIntersectionObserver({
    options = {
        root: null,
        rootMargin: "-100px",
        threshold: 0,
    },
}: Props) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            const [entry] = entries;
            setVisible(entry.isIntersecting);
        }, options);
        if (ref.current) observer.observe(ref?.current);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (ref?.current) observer.unobserve(ref?.current);
        };
    }, [ref, options]);
    return { ref, visible };
}
