type Props = {
    heading: string;
    tag: string;
    src: string;
};

export default function WorkCard({ heading, tag, src }: Props) {
    return (
        <div className="work-card">
            <div className="wrapper">
                <div
                    className="work-img"
                    style={
                        { "--background": `url(${src})` } as React.CSSProperties
                    }
                />
            </div>

            <h1>{heading}</h1>
            <span className="tag">{tag}</span>  
            <span className="details">View Details</span>
        </div>
    );
}
