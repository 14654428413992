import "../Styles/Expertise.scss";
type Props = {
    heading: string;
    body: string;
    subheading: string;
    Icon: JSX.Element;
};
export default function SkillCard({ subheading, heading, body, Icon }: Props) {
    return (
        <div className="skill-card">
            <div className="header">
                {Icon}
                <div className="card-heading">
                    <span>{heading}</span>
                    <h1>{subheading}</h1>
                </div>
            </div>
            <div className="body-container">
                <div className="body">{body}</div>
            </div>
        </div>
    );
}
