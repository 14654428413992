import SkillCard from "./SkillCard";
import "../Styles/Expertise.scss";
import { PiDesktopLight } from "react-icons/pi";
import { FaReact } from "react-icons/fa";
import { BiLogoNodejs } from "react-icons/bi";
import { useEffect } from "react";
export type Visible = {
    visible?: boolean;
};
export default function Expertise({ visible }: Visible) {
    useEffect(() => {
        let cards = document.querySelectorAll(".skill-card");
        console.log(cards);
        cards.forEach((card: Element) => {
            let timeout = setTimeout(() => {
                card.classList.add("fade-in");
            }, 500);
            return () => clearTimeout(timeout);
        });
    }, [visible]);
    return (
        <>
            <h1 className="heading">My Expertise</h1>
            <div id="skills-container">
                <SkillCard
                    heading="Software"
                    subheading="Engineering"
                    body="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi fugiat laboriosam voluptatum? In quos rerum cumque officiis. Ducimus, animi enim!"
                    Icon={<PiDesktopLight />}
                />
                <SkillCard
                    heading="Frontend Dev"
                    subheading="React, NextJS"
                    body="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi fugiat laboriosam voluptatum? In quos rerum cumque officiis. Ducimus, animi enim!"
                    Icon={<FaReact />}
                />
                <SkillCard
                    heading="Backend Dev"
                    subheading="NodeJS"
                    body="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi fugiat laboriosam voluptatum? In quos rerum cumque officiis. Ducimus, animi enim!"
                    Icon={<BiLogoNodejs />}
                />
            </div>
        </>
    );
}
