import { useState } from "react";
import { Exp } from "./Experience";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
export default function Accordion({
    heading,
    years,
    tags,
    location,
    description,
    src,
    companyLink
}: Exp) {
    const [expand, setExpand] = useState<boolean>(false);
    const Tech = tags.map((tag, i) => (
        <div className="tag" key={i}>
            {tag}
        </div>
    ));
    return (
        <div className={expand ? "accordion expand-accordion" : "accordion"}>
            <div className="accordion-body">
                <span>{heading}</span>
                <div>
                    <span>{years}</span>
                    <button
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        {expand ? (
                            <BsChevronCompactUp />
                        ) : (
                            <BsChevronCompactDown />
                        )}
                    </button>
                </div>
            </div>
            <div className="description">
                <div className="location">
                    <FaLocationDot />
                    <span>{location}</span>
                </div>
                <div className="desc-row">
                    <span>
                        {description}
                    </span>
                    <img src={src} alt="" className="logo" />
                    <div className="tags-container">{Tech}</div>
                </div>
            </div>
        </div>
    );
}
