import WorkCard from "./WorkCard";
import "../Styles/Work.scss";
import { Visible } from "./Expertise";
export default function Work({ visible }: Visible) {
    return (
        <>
            <h1 className="heading">My Work</h1>
            <div className="work-container">
                <WorkCard heading="lorem" tag="lorem" src="/images/st1.png" />
                <WorkCard heading="lorem" tag="lorem" src="/images/st1.png" />
                <WorkCard heading="lorem" tag="lorem" src="/images/st1.png" />
            </div>
        </>
    );
}
