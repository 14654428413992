import "../Styles/Experience.scss";
import Accordion from "./Accordion";
import { Visible } from "./Expertise";
export type Exp = {
    heading: string;
    years: string;
    tags: Array<string>;
    location: string;
    description: string;
    src: string;
    companyLink: string;
};
export default function Experience({ visible }: Visible) {
    const experience: React.JSX.Element[] = [
        {
            heading: "Undergraduate Intern, Dell Technologies",
            years: "Jan '23 - May '23",
            tags: ["React", "ReactFlow", "Chakra UI", "MongoDB", "Flask"],
            location: "Bangalore, India",
            description:
                "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum praesentium delectus sed, voluptate quam aspernatur.",
            src: "/images/dell.png",
            companyLink: "",
        },
        {
            heading: "Undergraduate Intern, Dell Technologies",
            years: "Jul '22 - Aug '22",
            tags: ["Angular", "Python"],
            location: "Bangalore, India",
            description:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste voluptatum sed ipsum, dignissimos magni laborum perferendis harum unde cupiditate ab?",
            src: "/images/dell.png",
            companyLink: "",
        },
        {
            heading: "Tech Manager, Authority Entrepreneurs",
            years: "Jul '21 - Dec '21",
            tags: ["HTML", "CSS", "JS", "Python", "CRM"],
            location: "Mumbai, India",
            description:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis labore corporis soluta. In, fugiat laudantium.",
            src: "/images/ae.png",
            companyLink: "",
        },
    ].map((exp: Exp, i: number) => (
        <Accordion
            heading={exp.heading}
            years={exp.years}
            tags={exp.tags}
            location={exp.location}
            description={exp.description}
            src={exp.src}
            companyLink={exp.companyLink}
            key={i}
        />
    ));
    return (
        <>
            <h1 className="heading">Experience</h1>
            <div className="exp-container">{experience}</div>
        </>
    );
}
